import React from "react";
import "./Flip.css";

const Flip = ({
  front,
  back,
  animate,
  onAnimationEnd,
  onMouseEnter,
  onMouseLeave,
  onClick
}) => {
  return (
    <button
      className="flip-card"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <div
        className={`flip-card-inner ${animate ? "animate" : ""}`}
        onAnimationEnd={onAnimationEnd}
      >
        <div className="flip-card-front">{front}</div>
        <div className="flip-card-back">{back}</div>
      </div>
    </button>
  );
};

export { Flip };
