import { useState, useEffect } from "react";

const getDevice = () => (window.innerWidth < 1024 ? "mobile" : "desktop");

const useDevice = () => {
  const [device, setDevice] = useState(getDevice());

  useEffect(() => {
    const handleResize = () => {
      setDevice(getDevice());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return device;
};

export { useDevice };
