import React from "react";
import { Navbar } from "../Navbar/Navbar";
import { Carousel } from "../Carousel/Carousel";
import "./Header.css";

const Header = () => {
  return (
    <div className="header-container" id="home" >
      <Navbar className="home-navbar-container" />
      <Carousel />
    </div>
  );
};

export { Header };
