import React, { useState, useEffect, useCallback, useRef } from "react";
import { CarouselDots } from "./CarouselDots";
import slider0 from "../../assets/images/slider_image_0.png";
import slider1 from "../../assets/images/slider_image_1.png";
import slider2 from "../../assets/images/slider_image_2.png";
import slider3 from "../../assets/images/slider_image_3.png";
import mobile_slider0 from "../../assets/images/mobile_carousel_0.jpg";
import mobile_slider1 from "../../assets/images/mobile_carousel_1.jpg";
import mobile_slider2 from "../../assets/images/mobile_carousel_2.jpg";
import mobile_slider3 from "../../assets/images/mobile_carousel_3.jpg";
import { useDevice } from "../../hooks/useDevice";
import "./Carousel.css";
import { CarouselArrows } from "./CarouselArrows";

const imageMapper = {
  mobile: [mobile_slider0, mobile_slider1, mobile_slider2, mobile_slider3],
  desktop: [slider0, slider1, slider2, slider3]
};

const Carousel = () => {
  const device = useDevice();
  const carouselImages = imageMapper[device];

  const slides = [
    {
      heroLogo: carouselImages[0],
      heroTitle: (
        <span className="hero-text-wrapper">
          <em className="hero-text-highlight">TECHNOLOGY </em> <br /> for people
          who can see<em className="hero-text-highlight"> TOMORROW</em>
        </span>
      )
    },
    {
      heroLogo: carouselImages[1],
      heroTitle: (
        <span className="hero-text-wrapper">
          <em className="hero-text-title">Expertise in LATAM :</em> <br />
          <em className="hero-text-description">
            We know the LATAM market <br /> and cultue inside out therefore
            bringing our own India's tech talents, we aim to form global teams,
            guaranteeing success for LATAM partners.
          </em>
        </span>
      )
    },
    {
      heroLogo: carouselImages[2],
      heroTitle: (
        <span className="hero-text-wrapper">
          Black Belt level expertise for
          <em className="hero-text-highlight"> DIGITAL RETAIL </em>Evolution
        </span>
      )
    },
    {
      heroLogo: carouselImages[3],
      heroTitle: (
        <span className="hero-text-wrapper">
          <em className="hero-text-title">
            Your partner in personlized DIGITAL TRANSFORMATION:
          </em>
          <em className="hero-text-description">
            <br /> We transform your business with best of the tech solutions
            suited to your problems.
          </em>
        </span>
      )
    }
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const intervalRef = useRef();

  const goToNextSlide = useCallback(() => {
    setCurrentImageIndex(prevIndex =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(goToNextSlide, 5000);
  }, [slides.length]);

  const resetInterval = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(goToNextSlide, 5000);
  }, [goToNextSlide]);

  const goToPrevSlide = () => {
    setCurrentImageIndex(prevIndex =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
    resetInterval();
  };

  const goToSlide = index => {
    setCurrentImageIndex(index);
    resetInterval();
  };

  useEffect(() => {
    resetInterval();
    return () => clearInterval(intervalRef.current);
  }, [resetInterval]);

  return (
    <div className="slider-container">
      {slides.map(({ heroLogo, heroTitle }, index) => (
        <div
          key={index}
          className={`slide-wrapper ${
            index === currentImageIndex ? "active" : "inactive"
          }`}
        >
          <img
            className="slide-image fade-in-animation"
            key={index}
            src={heroLogo}
            alt={`slide-${index}`}
          />
          <p className="carousel-text"> {heroTitle}</p>
        </div>
      ))}
      <CarouselArrows
        onLeftArrowClick={goToPrevSlide}
        onRightArrowClick={goToNextSlide}
      />

      <CarouselDots
        onDotClick={goToSlide}
        activeIndex={currentImageIndex}
        length={slides.length}
        className="dots"
      />
    </div>
  );
};

export { Carousel };
