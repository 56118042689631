import React from 'react'
import { GradientCircle } from '../../components/Gradient/GradientCircle'
import { Header } from '../../components/Header/Header'
import { About } from '../../components/AboutPage/About'
import { Services } from '../../components/Services/Services'
import { Team } from '../../components/Team-Section/Team'
import { Benefit } from '../../components/Benefit/Benefit'
import { Value } from '../../components/Value/Value'
import "./HomePage.css";

const HomePage = () => {
  return (
    <div className="home-gradient">
      <div className="about-gradient-circle" >
        <GradientCircle size={800} />
      </div>

      <div className="team-gradient-circle" >
        <GradientCircle size={800} /> </div>

      <div className="benefits-gradient-circle" >
        <GradientCircle size={800} /> </div>
      <Header />
      <About />
      <Services />

      <Team />
      <Benefit />
      <Value />

    </div>
  )
}

export { HomePage }
