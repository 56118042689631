import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage/HomePage";
import { CareersPage } from "./pages/CareersPage/CareersPage";
import { ScrollRestoration } from "./components/ScrollRestoration/ScrollRestoration";
import { AppShell } from "./pages/AppShell";

const App = () => {
  return (
    <BrowserRouter basename="/">
      <ScrollRestoration>
        <Routes>
          <Route element={<AppShell />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/careers" element={<CareersPage />} />
          </Route>
        </Routes>
      </ScrollRestoration>
    </BrowserRouter>
  );
};

export default App;
