import React from "react";
import aboutCompany from "../../assets/images/aboutCompany.png";
import checklist_ornament from "../../assets/images/checklist_ornament.png";
import { Layout, Left, Right } from "../Layout/Layout";
import { GreenDot } from "../Designs/GreenDot/GreenDot";
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";
import "./About.css";
import { Button } from "../Button/Button";

const About = () => {
  const [ref, isVisible] = useAnimateOnScroll();

  const scrollToContact = () => {
    const section = document.getElementById("footer");
    section.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div ref={ref}>
      <Layout className="about-section" id="about">
        <Left>
          <div
            className={`about-content ${
              isVisible ? "swipe-from-left" : "invisible"
            }`}
          >
            <h2 className="about-title">
              <em className="about-text-highlight">Your</em> trusted partner in{" "}
              <em className="about-text-highlight">Digital Engineering</em> and{" "}
              <em className="about-text-highlight">
                Technology Transformation
              </em>
            </h2>
            <p className="about-para">
              With over 15 years of combined experience, AndinoLabs specializes
              in delivering high-impact, customer-centric solutions in Digital
              Commerce, Data Analytics, and Supply Chain Logistics...
            </p>
            <Button className="about-button" onClick={scrollToContact}>
              Get in Touch!
            </Button>
          </div>
        </Left>

        <Right>
          <div
            className={`about-image-wrapper ${
              isVisible ? "swipe-from-right" : "invisible"
            }`}
          >
            <GreenDot className="greenDot-position" />

            <img
              className="about-image"
              src={aboutCompany}
              alt="group of employees"
            />
            <img
              className="about-checklist-ornament"
              src={checklist_ornament}
              alt="checklist-img"
            />
          </div>
        </Right>
      </Layout>
    </div>
  );
};
export { About };
