import React from "react";
import "./CarouselDots.css";

const CarouselDots = ({ activeIndex, length, className = "", onDotClick }) => {
  const handleClick = currentIndex => () => {
    onDotClick(currentIndex);
  };
  return (
    <div className={`dots-container ${className}`}>
      {Array.from({ length }, (_, index) => (
        <button
          key={index}
          onClick={handleClick(index)}
          className={`${activeIndex === index ? "circle solid" : "circle"}`}
        />
      ))}
    </div>
  );
};

export { CarouselDots };
