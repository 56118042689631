import React from "react";
import { Button } from "../Button/Button";
import "./JoinUs.css";
import { useNavigate } from "react-router-dom";

const JoinUs = () => {
  const navigate = useNavigate();
  const navigateToCulture = () => {
    navigate("/", { state: { targetSection: "values" } });
  };
  const navigateToJobs = () => {
    const section = document.getElementById("openings");
    section.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="joinus-container" id="about">
      <h1 className="joinus-title">Join us</h1>
      <p className="joinus-description">
        Welcome to the career opportunities at Andinolabs! We’re excited to find
        individuals who are passionate, driven, and ready to make an impact. At
        AndinoLabs, we value creativity, collaboration, and continuous learning.
        We offer a dynamic work environment where you can grow your skills, take
        on exciting challenges, and be part of a team that's shaping the future
        of digital commerce. Explore our openings and discover how you can
        contribute to our innovative projects and vibrant company culture. Join
        us and be a part of something truly amazing!
      </p>
      <div className="joinus_button-wrapper">
        <Button className="joinus-btn" onClick={navigateToJobs}>
          Join the team
        </Button>

        <Button
          className="joinus-btn"
          variant="outline"
          onClick={navigateToCulture}
        >
          Culture
        </Button>
      </div>
    </div>
  );
};

export { JoinUs };
