import React from 'react';
import './GradientCircle.css';

const GradientCircle = ({ size }) => {

  const circleStyle = {
    width: size,
    height: size
  };

  return (
    <div className="gradient-circle" style={circleStyle} ></div>
  );
};

export { GradientCircle };

