import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollRestoration = ({ children }) => {
  const { pathname, state } = useLocation();
  const { targetSection } = state || {};
  useLayoutEffect(() => {
    if (targetSection) {
      const section = document.getElementById(targetSection);
      if (section) {
        setTimeout(() => {
          section.scrollIntoView({ behavior: "smooth" });
        }, 800);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, targetSection]);
  return children;
};

export { ScrollRestoration };
