import React, { Fragment } from "react";
import group_mobile from "../../assets/images/group_image.png";
import group_desktop from "../../assets/images/group_image_desktop.png";
import { useDevice } from "../../hooks/useDevice";
import "./Team.css";
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";

const Team = () => {
  const device = useDevice();
  const image = device === "mobile" ? group_mobile : group_desktop;
  const [ref, isVisible] = useAnimateOnScroll();
  const [shapeRef, shapeIsVisible] = useAnimateOnScroll();
  return (
    <Fragment>
      <section className="team-container" id="why-us">
        <h2
          ref={ref}
          className={`team-title ${
            isVisible ? "team-shrink-font" : "invisible"
          }`}
        >
          Our location kind of make us
          <em className="team-text">SUPER AWESOME!</em>
        </h2>
        <p className="description">
          Nestled in the tech heart of Bangalore, the Retail/CPG hub of India,
          <em className="team-text-color"> Andinolabs</em> stands at the
          forefront of digital innovation.
          <br />
          Home to over <em className="team-text-color">40% </em>of the world’s
          retail giants, our prime location gives us a unique vantage point to
          harness top tech talent and a cutting-edge digital infrastructure. We
          are part of a vibrant ecosystem where state-of-the-art technology
          meets breakthrough innovation. As your partner, we promise to navigate
          the digital landscape with pioneering solutions that are as robust as
          they are revolutionary. Whether you need to upgrade your legacy
          systems, move to the cloud, or build a new app, we are skilled to make
          it happen.
        </p>
        <b
          ref={shapeRef}
          className={`shape-text ${
            shapeIsVisible ? "team-compress-font" : "invisible"
          }`}
        >
          Let’s shape a digitally empowered future, together.
        </b>
      </section>
      <img src={image} className="team-image" alt="office_team" />
    </Fragment>
  );
};

export { Team };
