import React from "react";
import { useDevice } from "../../hooks/useDevice";

const mapContainerStyle = {
  mobile: {
    height: "340px",
    width: "auto",
    borderRadius: "20px"
  },
  desktop: {
    minWidth: "450px",
    width: "45%",
    height: "auto",
    borderRadius: "20px"
  }
};

const GoogleMaps = () => {
  const device = useDevice();

  return (
    <iframe
      className=""
      title="google-maps"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.9420901259705!2d77.63900457482067!3d13.039358113389634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae172b65dcf0a5%3A0xc601738d9d204cf5!2sSYMPHONY%20Managed%20Office%20Space!5e0!3m2!1sen!2sin!4v1712939218986!5m2!1sen!2sin"
      width={mapContainerStyle[device].width}
      height={mapContainerStyle[device].height}
      style={{ border: "0", borderRadius: "20px" }}
      allowFullScreen={false}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export { GoogleMaps };
