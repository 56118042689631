import { useEffect, useState } from "react";

const useDictionary = componentName => {
  const [language, setLanguage] = useState("english");
  const [labels, setLabels] = useState({});

  useEffect(() => {
    import(`./${language}.js`)
      .then(module => {
        setLabels(module.labels);
      })
      .catch(error => {
        console.error(`Failed to load language file for ${language}:`, error);
      });
  }, [language]);

  return { dictionary: labels[componentName] || {}, language, setLanguage };
};

export { useDictionary };
