import React from "react";
import InnovateFearlessly from "../../assets/images/Innovate_Fearlessly.png";
import createjoy from "../../assets/images/create_joy.png";
import simplify from "../../assets/images/simplify.png";
import unite from "../../assets/images/unite.png";
import { FlipGroup } from "../Flip/FlipGroup";
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";
import "./ValueList.css";

const cards = [
  {
    front: (
      <div>
        <img src={InnovateFearlessly} alt="innovate" />
        <h3 className="flip-front-title">Innovate Fearlessly</h3>
      </div>
    ),
    back: (
      <span className="flip-back-description">
        At AndinoLabs, innovation is our essence, fuelled by an entrepreneurial
        spirit. We courageously embrace change, turning challenges into
        opportunities, and continually pushing the envelope for meaningful
        impact.
      </span>
    )
  },
  {
    front: (
      <div>
        <img src={simplify} alt="simplify" />
        <h3 className="flip-front-title">Simplify Excellence</h3>
      </div>
    ),
    back: (
      <span className="flip-back-description">
        We believe in the power of simplicity. By focusing on what truly
        matters, we deliver exceptional value with precision, exceeding
        expectations in everything we do.
      </span>
    )
  },
  {
    front: (
      <div>
        <img src={createjoy} alt="createjoy" />
        <h3 className="flip-front-title">Create Joy with Technology</h3>
      </div>
    ),

    back: (
      <span className="flip-back-description">
        Our technology goes beyond functionality; it's about crafting delightful
        experiences. Each project is a chance to spread joy, making every
        interaction with our solutions a positive, inspiring moment.
      </span>
    )
  },
  {
    front: (
      <div>
        <img src={unite} alt="unite" />
        <h3 className="flip-front-title">Unite for Success</h3>
      </div>
    ),

    back: (
      <span className="flip-back-description">
        Strength lies in unity. Our collaborative culture brings together
        diverse perspectives, creating a powerhouse of ideas that drive us
        toward a shared vision of success.
      </span>
    )
  }
];

const ValuesList = () => {
  const [ref, isVisible] = useAnimateOnScroll();
  return (
    <div className="flip-card-container" ref={ref}>
      <FlipGroup cards={cards} startAnimation={isVisible} />
    </div>
  );
};

export default ValuesList;
