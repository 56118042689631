import React, { Fragment, useState } from "react";
import { Layout, Left, Right } from "../Layout/Layout";
import male from "../../assets/images/male.png";
import female from "../../assets/images/female.png";
import "./Benefit.css";
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";
import { Button } from "../Button/Button";

const benefits = [
  {
    heading: (
      <Fragment>
        Dynamic, Trusted,{" "}
        <em className="benefit-text-highlight">Innovative </em>
        Solutions
      </Fragment>
    ),
    description: (
      <Fragment>
        AndinoLabs is your dynamic, trusted, and innovative solutions partner,
        offering expertise in Digital Commerce, Data Analytics, and Supply Chain
        Logistics. We excel in Evolutionary Architecture, Microservices, Legacy
        Modernization, Cloud Migrations, and App Development.
      </Fragment>
    ),
    expandedDescription: (
      <Fragment>
        AndinoLabs is your dynamic, trusted, and innovative solutions partner,
        offering expertise in Digital Commerce, Data Analytics, and Supply Chain
        Logistics. With over 15 years of combined experience, our team excels in
        creating solutions that drive business growth and customer engagement.
        We specialize in Evolutionary Architecture, Microservices, Legacy
        Modernization, Cloud Migrations, and App Development, ensuring that our
        clients stay ahead of the curve in today's competitive landscape.
      </Fragment>
    ),
    image: <img src={male} className="benefit-image-male" alt="reliable" />,
    imageAnimation: "swipe-from-left"
  },
  {
    heading: (
      <Fragment>
        <em className="benefit-text-highlight">Transformative</em> Solutions for
        Tomorrow
      </Fragment>
    ),
    description: (
      <Fragment>
        With a multilingual team and a passion for nature, we deliver
        transformative solutions that bring joy to your business and drive
        digital transformation. Choose AndinoLabs for reliable, impactful, and
        customer-centric solutions.
      </Fragment>
    ),
    expandedDescription: (
      <Fragment>
        At AndinoLabs, we believe in delivering transformative solutions that
        not only meet your current needs but also anticipate future challenges.
        Our multilingual team is committed to understanding your unique business
        requirements and crafting solutions that drive digital transformation.
        With a passion for nature and a focus on sustainability, we are
        dedicated to creating solutions that not only bring joy to your business
        but also contribute to a brighter, more sustainable future. Choose
        AndinoLabs for reliable, impactful, and customer-centric solutions that
        propel your business forward.
      </Fragment>
    ),
    image: <img src={female} className="benefit-image-female" alt="reliable" />,
    imageAnimation: "swipe-from-right"
  }
];

const Benefit = () => {
  const ref0 = useAnimateOnScroll();
  const ref1 = useAnimateOnScroll();
  const refs = [ref0, ref1];
  const [expandedIndex, setExpandedIndex] = useState([]);

  const showMoreInformation = index => () => {
    if (expandedIndex.includes(index)) {
      setExpandedIndex(prev => prev.filter((item) => item !== index));
    } else {
      setExpandedIndex(prev => ([...prev, index]));
    }
  };
  return (
    <div className="benefits-container">
      {benefits.map(
        (
          { heading, description, expandedDescription, image, imageAnimation },
          index
        ) => {
          const isExpanded = expandedIndex.includes(index);
          return (
            <Layout
              className="benefit-section"
              ref={refs[index][0]}
              key={index}
            >
              <Left>
                <section className={`benefit-content-${index}`}>
                  <h2 className="benefit-title">{heading}</h2>
                  <p className="benefit-para">
                    {isExpanded ? expandedDescription : description}
                  </p>

                  <Button
                    id="benefit-learn-more"
                    variant="link"
                    className="benefit-learn-more"
                    onClick={showMoreInformation(index)}
                  >
                    {isExpanded ? "Show less" : "Learn more"}
                  </Button>
                </section>
              </Left>
              <Right>
                <div
                  className={`${refs[index][1] ? imageAnimation : "invisible"}`}
                >
                  {image}
                </div>
              </Right>
            </Layout>
          );
        }
      )}
    </div>
  );
};

export { Benefit };
