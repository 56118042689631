import React from 'react'
import { GradientCircle } from '../../components/Gradient/GradientCircle'
import { Navbar } from '../../components/Navbar/Navbar'
import { JoinUs } from '../../components/JoinUs/JoinUs'
import { Joinusbenefits } from '../../components/JoinUs-Benefits/Joinusbenefits'
import { Openings } from '../../components/Openings/Openings'
import "./CareersPage.css";

const CareersPage = () => {
  return (
    <div className="careers-gradient">
      <div className="join-us-gradient-circle" >
        <GradientCircle size={515} />
      </div>
      <Navbar className="careers-navbar" />
      <JoinUs />
      <Joinusbenefits />
      <Openings />
    </div>

  )
}

export { CareersPage }
