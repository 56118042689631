import React from "react";
import { useDictionary } from "../../dictionary/useDictionary";
import { ServiceList } from "../ServiceList/ServiceList";
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";
import "./Services.css";

const Services = () => {
  const [ref, isVisible] = useAnimateOnScroll();
  const { dictionary } = useDictionary("services");

  const applyStyle = style => (isVisible ? style : "");

  return (
    <section className="services-container" id="services">
      <h2
        ref={ref}
        className={`services-title ${applyStyle("services-shrink")}`}
      >
        {dictionary.services}
      </h2>
      <h3 className={`services-heading ${applyStyle("services-shrink")}`}>
        {dictionary.howCanWeHelpYou}
      </h3>
      <div className={`${applyStyle("slide-up-animation")}`}>
        <ServiceList />
      </div>
    </section>
  );
};

export { Services };
