import React from "react";
import "./CarouselArrows.css";

const CarouselArrows = ({ onLeftArrowClick, onRightArrowClick }) => {
  return (
    <div className="arrows">
      <button
        onClick={onLeftArrowClick}
        className="arrow"
        id="carousel-left-arrow"
      >
        &#9001;
      </button>
      <button
        onClick={onRightArrowClick}
        className="arrow"
        id="carousel-right-arrow"
      >
        &#9002;
      </button>
    </div>
  );
};

export { CarouselArrows };
