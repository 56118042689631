import React from "react";
import ValuesList from "../ValuesList/ValuesList";
import "./Value.css";

const Value = () => {
  return (
    <section className="value-section" id="values">
      <h2 className="value-title-section">
        <em className="first-text">Our Values:&nbsp;</em>
        <em className="value-subtitle-section">The Heart of Andinolabs</em>
      </h2>

      <p className="value-description">
        Our values are the guiding stars that light our path to creating
        impactful solutions <span>and forging meaningful connections.</span>
      </p>
      <ValuesList />
    </section>
  );
};

export { Value };
