import React from "react";
import { iconMapper } from "./iconMapper";

const Icon = ({ name, ...props }) => {
  const Symbol = iconMapper[name];
  if (!Symbol) {
    return undefined;
  }

  return <Symbol {...props} />;
};

export { Icon };
