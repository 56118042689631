import React, { useId } from "react";
import "./Button.css";

const Button = ({ id, variant = "primary", className = "", ...props }) => {
  const uniqueId = useId();

  return (
    <button id={`${id}-${uniqueId}`} className={`btn ${className} ${variant}`} {...props} />
  )
}

export { Button }