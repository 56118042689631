import React, { Fragment } from "react";
import { Button } from "../Button/Button";
import "./Openings.css";
import { useAnimateOnScroll } from "../../hooks/useAnimateOnScroll";

const jobDetails = [
  {
    role: "Generative AI/ML Engineer",
    exprience: "2-4 years",
    deadline: "Bangalore",
  },
  {
    role: "Automation Engineer",
    exprience: "2-4 years",
    deadline: "Bangalore",
  },
  {
    role: "Software engineer (Java)",
    exprience: "2-4 years",
    deadline: "Bangalore",
  },
  {
    role: "Software engineer (Node JS)",
    exprience: "2-4 years",
    deadline: "Bangalore",
  },
  {
    role: "API Automation tester",
    exprience: "2-4 years",
    deadline: "Bangalore",
  },
  // { role: "API Automation tester", exprience: "2-4 years", deadline: "2024-05-08" },
];

const Openings = () => {
  const [ref, isVisible] = useAnimateOnScroll();
  const sendEmail =
    ({ role }) =>
    () => {
      const email = "hr@andinolabs.com";
      const sub = `Application for ${role}`;
      window.open(
        `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&&su=${sub}`
      );
    };

  return (
    <div className="openings-section" id="openings" ref={ref}>
      <section
        className={`openings-head-section ${
          isVisible ? "openings-grow-txt" : "invisible"
        }`}
      >
        <h3 className="openings-theme">COME JOIN US</h3>
        <h2 className="openings-title">Career Openings</h2>
        <p className="openings-description">
          We’re always looking for creative, talented self-starters to join the
          AndinoLabs family. Check out our open roles below and fill out an
          application.
        </p>
      </section>
      <ul className="openings-wrapper">
        {jobDetails.map((detail, index) => (
          <li className="job-opening" key={index}>
            <div className="opening-role">{detail.role}</div>
            <dl
              className={`opening-details-wrapper ${
                detail.deadline ? "columns-2" : ""
              }`}
            >
              <dt className="opening-heading">Experience</dt>
              <dd className="opening-value">{detail.exprience}</dd>

              {detail.deadline && (
                <Fragment>
                  <dt className="opening-heading">Location</dt>
                  <dd className="opening-value">{detail.deadline}</dd>
                </Fragment>
              )}
            </dl>
            <Button
              onClick={sendEmail(detail)}
              className="opening-btn"
              id="job-apply-now"
            >
              Apply now
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { Openings };
