import React, { useState } from "react";
import { Flip } from "./Flip";

const FlipGroup = ({ cards, startAnimation }) => {
  const [activeIndex, setIndex] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleAnimationEnd = () => {
    const current = cards.length === activeIndex + 1 ? 0 : activeIndex + 1;
    setIndex(current);
  };

  const updateIndex = (index, immediate = false) => () => {
    const id = setTimeout(() => {
      setIndex(index);
    }, immediate ? 0 : 1000);
    setTimeoutId(id);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutId);
  };

  return cards.map((eachCard, index) => (
    <Flip
      {...eachCard}
      key={index}
      onClick={updateIndex(index, true)}
      onMouseEnter={updateIndex(index)}
      onMouseLeave={handleMouseLeave}
      animate={startAnimation ? index === activeIndex : false}
      onAnimationEnd={handleAnimationEnd}
    />
  ));
};

export { FlipGroup };
