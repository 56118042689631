import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../components/Footer/Footer";

const AppShell = () => {
  return (
    <Fragment>
      <Outlet />
      <Footer />
    </Fragment>
  );
};

export { AppShell };
