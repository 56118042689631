import React from "react";
import digitalCommerce from "../../assets/images/digital_commerce.jpg";
import dataAnalytics from "../../assets/images/data_analytics.jpg";
import supplyChainlogistics from "../../assets/images/supplyChain_logistics.jpg";
import "./SelectedServiceInfo.css";
import { Layout, Left, Right } from "../Layout/Layout";

const serviceInfos = {
  digitalCommerce: {
    description:
      "Transform your e-commerce experience with our innovative digital commerce solutions. We provide customizable platforms and tools to create engaging online stores, manage product catalogues, and deliver seamless shopping experiences across channels. With our solutions, you can drive sales, enhance customer loyalty, and stay agile in the ever-evolving digital marketplace.",
    title: "Revolutionize Your Online Retail Experience.",
    image: digitalCommerce
  },
  dataAnalytics: {
    description:
      "Harness the power of data to drive informed decisions and unlock new opportunities for your business. Our data analytics solutions provide comprehensive insights into customer behaviour, market trends, and operational performance. With advanced analytics tools and techniques, we help you optimize strategies, improve efficiency, and stay ahead in the competitive landscape.",
    title: "Empowering Your Business Through Data Analytics.",
    image: dataAnalytics
  },
  supplyChain: {
    description:
      "Optimize your supply chain and streamline logistics operations with our cutting-edge solutions. From inventory management to order fulfilment, we offer end-to-end visibility and control over your supply chain. Our solutions are designed to enhance efficiency, reduce costs, and improve delivery reliability, enabling you to meet customer demands and drive business growth.",
    title:
      "Enhancing Operational Efficiency Through Advanced Supply Chain Solutions.",
    image: supplyChainlogistics
  }
};

const SelectedServiceInfo = ({ serviceName }) => {
  const { description, title, image } = serviceInfos[serviceName];
  return (
    <Layout className="serviceinfo-container">
      <Left>
        <section className="serviceinfo-text-wrapper">
          <h4 className="serviceinfo-title">{title}</h4>
          <p className="serviceinfo-description">{description}</p>
        </section>
      </Left>
      <Right>
        <img className="service-image" src={image} alt="digital-commerce" />
      </Right>
    </Layout>
  );
};

export { SelectedServiceInfo };
