import React from "react";
import { GoogleMaps } from "../GoogleMaps/GoogleMaps";
import { Contact } from "../Contact/Contact";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer" id="footer">
      <Contact />
      <GoogleMaps />
    </footer>
  );
};

export { Footer };
