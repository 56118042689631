import React, { useState } from "react";
import { Icon } from "../Icon/Icon";
import { SelectedServiceInfo } from "../SelectedServiceInfo/SelectedServiceInfo";
import "./ServiceList.css";

const icons = ["digitalCommerce", "supplyChain", "dataAnalytics"];

const ServiceList = () => {
  const [activeItem, setActiveItem] = useState("digitalCommerce");
  const isActive = name => activeItem === name;
  const selectedItemIndex = icons.indexOf(activeItem);
  const getOrder = currentIndex =>
    currentIndex <= selectedItemIndex ? "order-0" : "order-1";

  const handleClick = name => () => {
    setActiveItem(name);
    setTimeout(() => {
      const section = document.querySelector(`#${name}`);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 0);
  };
  return (
    <div className="servicelist-container">
      {icons.map((name, index) => (
        <button
          key={name}
          id={name}
          className={`service-icon-btn ${
            isActive(name) ? "service-active" : ""
          } ${getOrder(index)}`}
          onClick={handleClick(name)}
        >
          <Icon name={name} active={isActive(name)} />
        </button>
      ))}
      <SelectedServiceInfo serviceName={activeItem} />
    </div>
  );
};

export { ServiceList };
