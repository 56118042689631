import React, { Fragment, forwardRef } from "react";
import "./Layout.css";

const Layout = forwardRef(
  ({ children, isReverse, className, id = "" }, ref) => {
    const leftComponent = React.Children.toArray(children).find(
      child => child.type.displayName === "left-section"
    );

    const rightComponent = React.Children.toArray(children).find(
      child => child.type.displayName === "right-section"
    );

    return (
      <div
        className={`${className} ${
          isReverse ? "info-section reverse" : "info-section"
        }`}
        id={id}
        ref={ref}
      >
        {leftComponent}
        {rightComponent}
      </div>
    );
  }
);

const Left = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

const Right = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

Left.displayName = "left-section";
Right.displayName = "right-section";

export { Layout, Left, Right };
